.account-selector__selector-header {
  .octolis__default-background-image {
    background-position: left !important;
  }
}
.account-selector__screen-container{
  .auth__animated-octo-img-container {
    opacity: 0 !important;
  }
  .auth__wave-container {
    opacity: 0 !important;
  }
}
