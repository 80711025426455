

:root {
  --main-bg-auth-color: #4f33fa;
  --main-auth-login-button-bg-color: #4f33fa;
}

.account-selector__screen-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: white;
}
.account-selector__selector-container {
  margin: auto;
  width: 50%;
  min-height: 500px;
  z-index: 2;
  padding: 24px;
  .account-selector__selector-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 24px;
    .account-selector__selector-header-logo {
      width: 100px;
      height: 35px;
      display: block;
    }
  }
  .account-selector__settings-menu {
    width: 250px;
    height: 250px;
  }
  .account-selector__selector-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 12px;
  }
  .account-selector__selector-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    max-height: 200px;
    .account-selector__selector-item-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 1px 0;
      padding: 13px 0;
      border-bottom: 2px solid whitesmoke;
      cursor: pointer;
      .account-selector__selector-item-title {
      }
      .account-selector__selector-item-icon {
        font-size: 24px;
        color: whitesmoke;
      }
      &:hover,
      &:active,
      &:focus {
        border-bottom: 2px solid var(--main-bg-auth-color);
        .account-selector__selector-item-icon {
          color: var(--main-bg-auth-color);
          transition: 1.5s;
        }
        transition: 1.5s;
      }
    }
  }
}

.logout__screen-container {
  display: flex;
  height: 100vh;
}
.logout__screen-left-side-container {
  position: relative;
  display: flex;
  width: 50%;
  background-color: white;
  .logout__screen-left-side-logout-section {
    margin: auto;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
  }
  .logout__screen-left-side-logout-section-input-label {
    margin-left: 2px;
  }
  .logout__screen-left-side-logout-section-img {
    width: 150px;
    height: 75px;
    display: block;
  }
  .logout__screen-left-side-logout-section-title {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .logout__screen-left-side-logout-section-button {
    color: white;
    width: 50%;
    margin-bottom: 24px;
    background-color: var(--main-auth-login-button-bg-color);
    .logout__screen-left-side-logout-section-button-text {
      font-size: 14px;
      font-weight: bold;
    }
  }
  .logout__screen-left-side-logout-get-in-touch-btn {
    padding: 0px;
    color: var(--main-auth-login-button-bg-color);
  }
}
.logout__screen-right-side-container {
  display: flex;
  width: 50%;
  position: relative;
  background-color: var(--main-bg-auth-color);
  .logout__screen-right-side-data-details-section {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .logout__screen-right-side-data-details-text {
      color: whitesmoke;
      font-weight: bold;
      width: 60%;
    }
    .logout__screen-right-side-data-details-img-container {
      width: 60%;
      height: 300px;
      .logout__screen-right-side-data-details-img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.login__container {
  height: 100vh;
  background-color: white;
}
.auth__wave-container {
  width: 100%;
  background-color: var(--main-wave-bg-auth-color);
  height: 250px;
  position: absolute;
  bottom: 0;
  .auth__wave-content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
  }

  .auth__wave-content svg {
    position: relative;
    display: block;
    width: calc(173% + 1.3px);
    height: 250px;
  }
  .auth__wave-content .auth__wave-shape-fill {
    fill: #ffffff;
  }
  .auth__wave-fill-color {
    fill: var(--main-bg-auth-color) !important;
  }
}
.auth__animated-octo-img-container {
  width: 150px;
  height: 200px;
  position: absolute;
  bottom: 50px;
  right: 80px;
  z-index: 2;
}
.auth__animated-octo-img {
  position: relative;
  animation-name: auth_img-animation;
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
@keyframes auth_img-animation {
  0% {
    top: 0px;
  }

  50% {
    top: 30px;
  }

  100% {
    top: 0px;
  }
}
