@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../font/WorkSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 500;
  src: url('../font/WorkSans-Medium.ttf') format('truetype');
}

@import './generic.less';
@import './settings.less';
@import './octolis.less';
@import './refactors.less';

:root {
  --main-bg-color: #4f33fa;
  --main-green-color: #559f19;
  --main-red-color: #f80d0d;
  --main-grey-color: #e2e2e9;
  --main-hover-border-color: #1890ff;
  --main-invariant-error-color: #eb4f4f;
  --main-invariant-error-text-color: #7e3b37;
  --main-eventual-error-color: #ffe99c;
  --main-eventual-error-text-color: #7e7337;
}
.red-color {
  color: var(--main-red-color);
}
.green-color {
  color: var(--main-green-color);
}
.grey-color {
  color: var(--main-grey-color);
}
a {
  color: var(--main-primary-color);
}

html,
body {
  font-family: 'Work Sans', sans-serif;
  font-feature-settings: 'tnum', 'tnum';
  font-size: 14px;
  font-variant: tabular-nums;
  height: auto;
  line-height: 1.5715;
  margin: 0;
  min-height: 100%;
  min-width: 100%;
  width: auto;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  margin-bottom: 0.5em;
  margin-top: 0;
}
