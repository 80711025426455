.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: var(--main-primary-color);
}

.ant-layout-sider {
  background: var(--main-bg-color);
}
.ant-layout-sider-trigger {
  background: var(--layout-trigger-background);
}
.ant-menu.ant-menu-dark,
.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark .ant-menu-sub {
  background: var(--main-bg-color);
}
.ant-menu-dark .ant-menu-item {
  span {
    color: #ffff;
  }
}
.ant-btn-primary {
  background: var(--main-bg-color);
  border-color: var(--main-bg-color);
}
.ant-tabs-top > .ant-tabs-nav {
  margin: 0;
}
.ant-tabs-tabpane {
  margin: 0;
}
.ant-btn[disabled] {
  border-radius: 6px;
}

.ant-drawer-content {
  background-color: #f5f6fa !important;
}

// Override  antD popover css
.ant-popover-arrow {
  display: none;
}

.ant-popover-inner-content {
  padding: 4px 1px;
}
.ant-popover-inner-content-custom-padding {
  .ant-popover-inner-content {
    padding: 12px 16px;
  }
}
.business-terms-modal-container {
  .ant-modal-body {
    padding-bottom: 0;
  }
}
// Override border radius
.ant-popover-inner {
  border-radius: 6px;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-input,
.ant-input-number,
.ant-picker {
  border-radius: 6px;
}
.ant-input-number {
  width: 100%;
}
.ant-select-selection-placeholder {
  margin-left: 10px;
}
// Same as primary btn octolis__primary-btn
.ant-modal-confirm-btns {
  .ant-btn-primary {
    background-color: #0097a7;
    border-color: #0097a7;
    color: #f1f1f1;
    &:hover,
    &:active,
    &:focus {
      background-color: #0097a7;
      color: #f1f1f1;
    }
  }
}
.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 0 6px 6px 0;
}

.source__table-container,
.dataset__table-container,
.sync__table-container,
.dataModel__table-container {
  .ant-table table {
    border-collapse: collapse;
  }
}

.ant-menu-item {
  .ant-btn-text:not(:disabled):not(.ant-btn-disabled):hover {
    color: unset;
  }
}
